<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-form lazy-validation ref="form" @submit.prevent="submit">
        <v-card-title>
          <span class="text-h5" v-if="input.id">{{
            $t("user.update-user")
          }}</span>
          <span class="text-h5" v-else>{{ $t("user.add-company-user") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="form">
              <v-col cols="12">
                <v-text-field
                  :label="`${$t('label.username')}*`"
                  class="my-0 py-0"
                  v-model="input.username"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="`${$t('label.firstname')}*`"
                  class="my-0 py-0"
                  v-model="input.first_name"
                  :rules="[validationRule.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="`${$t('label.lastname')}*`"
                  class="my-0 py-0"
                  v-model="input.last_name"
                  :rules="[validationRule.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="`${$t('label.email')}*`"
                  class="my-0 py-0"
                  v-model="input.email"
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="input.id">
                <v-text-field
                  :label="`${$t('label.old-password')}*`"
                  class="my-0 py-0"
                  type="password"
                  v-model="input.old_password"
                  :rules="[validationRule.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="`${$t('label.password')}*`"
                  class="my-0 py-0"
                  type="password"
                  v-model="input.password"
                  :rules="passwordRules"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mb-1" color="grey darken-1" text @click="close">
            {{ $t("common.close") }}
          </v-btn>
          <v-btn class="mb-1" color="primary darken-1" type="submit">
            {{ $t("common.save") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      input: {},
    };
  },
  methods: {
    ...mapActions({
      addCompanyUser: "users/addCompanyUser",
      updateUser: "users/updateUser",
    }),
    submit() {
      if (this.$refs.form.validate()) {
        const params = {
          first_name: this.input.first_name,
          last_name: this.input.last_name,
          email: this.input.email,
          password: this.input.password,
        };
        if (this.input.id) {
          // call update user api
          Object.assign(params, { old_password: this.input.old_password });
          this.updateUser({ params, id: this.input.id })
            .then(() => this.$emit("refresh-user"))
            .catch((error) => console.log(error));
        } else {
          // call add user api
          this.addCompanyUser(params)
            .then(() => this.$emit("refresh-user"))
            .catch((error) => console.log(error));
        }
        this.close();
      }
    },
    open(item) {
      this.input = { ...item };
      this.dialog = true;
    },
    close() {
      this.$refs.form.resetValidation();
      this.dialog = false;
    },
  },
};
</script>
