<template>
  <div>
    <v-card flat color="accent rounded-0">
      <v-row>
        <v-col md="2">
          <box-regular :title="$t('dashboard.title')" value="121"></box-regular>
        </v-col>
      </v-row>
    </v-card>

    <div class="common-container">
      <div class="box">
        <div class="box-title">Title text</div>
        <h1 class="num">40</h1>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment/dist/moment";
// import i18n from "@/i18n";

export default {
  name: "dashboard",
  async mounted() {
    await this.fetchLocation().then((data) => this.fetchBoxStock(data));
  },
  data() {
    return {
      data: [],
    };
  },
  methods: {
    ...mapActions({
      fetchLocation: "dashboard/fetchLocation",
      fetchBoxStock: "dashboard/fetchBoxStock",
    }),

    formattedDate(date) {
      return date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "";
    },
  },
  computed: {
    ...mapGetters({
      locations: "dashboard/getLocations",
      boxStocks: "dashboard/getBoxStock",
    }),
  },
};
</script>

<style>
.box {
  width: 16rem;
  height: 8rem;
  padding: 2rem;
  border: 1px solid #000;
  background: rgb(238, 238, 236);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.box-title {
  min-width: 8rem;
  padding: 0.1rem 1rem;
  background: #9ac31c;
  color: #fff;
  position: absolute;
  top: -0.75rem;
  left: 0.5rem;
  border-radius: 6px;
}
.num {
  color: #000;
}
</style>
