<template>
  <div style="height: 92vh">
    <v-card flat color="accent rounded-0">
      <v-row>
        <v-col md="2">
          <!-- <box-regular username="Operation"></box-regular> -->
        </v-col>
      </v-row>
    </v-card>

    <section class="px-8 my-8">
      <h2 class="my-2">Users</h2>
      <v-data-table
        :headers="headers"
        :items="users"
        :items-per-page="5"
        class="elevation-0"
        :hide-default-footer="users.length < 5"
      ></v-data-table>
    </section>

    <v-fab-transition>
      <v-btn
        fab
        fixed
        right
        bottom
        color="var(--v-primary-base)"
        class="add-user-btn"
        @click="$refs.createUserDialogRef.open()"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <create-user-dialog
      ref="createUserDialogRef"
      @create-user="createUser"
    ></create-user-dialog>

    <manage-company-user-dialog
      ref="manageCompanyUserDialogRef"
      @refresh-user="fetchUsersTable"
    ></manage-company-user-dialog>

    <manage-device-user-dialog
      ref="manageDeviceUserDialogRef"
      @refresh-user="fetchUsersTable"
    ></manage-device-user-dialog>

    <confirm-dialog ref="confirm"></confirm-dialog>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { mapActions, mapGetters } from "vuex";
import ManageCompanyUserDialog from "@/components/dialogs/ManageCompanyUserDialog.vue";
import CreateUserDialog from "@/components/dialogs/CreateUserDialog.vue";
import ManageDeviceUserDialog from "@/components/dialogs/ManageDeviceUserDialog.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";

export default {
  name: "users",
  async mounted() {
    this.fetchUsersTable();
  },
  components: {
    ManageCompanyUserDialog,
    CreateUserDialog,
    ManageDeviceUserDialog,
    ConfirmDialog,
  },
  data() {
    return {
      username: null,
      firstName: null,
      lastName: null,
      limit: 10,
      user_type: null,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Firstname", value: "first_name" },
        { text: "Lastname", value: "last_name" },
        { text: "Email", value: "email" },
        { text: "Username", value: "username" },
        { text: "Type", value: "type" },
      ],
      options: {
        bgcolor: "error darken-3",
        color: "white",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
    };
  },
  methods: {
    ...mapActions({
      fetchUsersTable: "users/fetchUsersTable",
      fetchUsersByFilter: "users/fetchUsersByFilter",
      addUser: "users/addUser",
      deleteUser: "users/deleteUser",
    }),
    createUser(userType) {
      this.user_type = userType;
      if (userType === "company_user") {
        this.$refs.manageCompanyUserDialogRef.open();
      } else {
        this.$refs.manageDeviceUserDialogRef.open(userType);
      }
    },
    async confirmDeleteUser(user) {
      if (
        await this.$refs.confirm.open(
          `${i18n.t("user.delete-user")}`,
          `${i18n.t("user.delete-message", { username: user.username })}`,
          this.options
        )
      ) {
        this.deleteRecord(user.id);
      }
    },
    deleteRecord(id) {
      this.deleteUser(id)
        .then(() => {
          this.fetchUsersTable();
        })
        .catch((err) => console.log("Delete user error :", err));
    },

    searchQuery() {
      let username = `username=${this.username}`;
      let firstname = `first_name=${this.firstName}`;
      let lastname = `last_name=${this.lastName}`;

      let arr = [];
      this.username && arr.push(username);
      this.firstName && arr.push(firstname);
      this.lastName && arr.push(lastname);
      let query = "?" + arr.join("&");

      this.fetchUsersByFilter(query);
    },
  },
  computed: {
    ...mapGetters({
      users: "users/getUsersTable",
    }),
  },
};
</script>

<style></style>
