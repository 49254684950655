<template>
  <v-dialog v-model="dialog" persistent max-width="1000px">
    <v-card>
      <v-form lazy-validation ref="form" @submit.prevent="submit">
        <v-card-title>
          <span class="text-h5" v-if="input.id">{{
            $t("user.update-user")
          }}</span>
          <span class="text-h5" v-else>{{ $t("user.add-device-user") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <form class="d-flex flex-column">
              <v-row>
                <v-col cols="12" md="6" class="col-md-6">
                  <v-text-field
                    :label="$t(`label.firstname`)"
                    outlined
                    v-model="input.first_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="col-md-6">
                  <v-text-field
                    :label="$t(`label.lastname`)"
                    outlined
                    v-model="input.last_name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="col-md-6">
                  <v-text-field
                    :label="$t(`label.username`)"
                    outlined
                    v-model="input.username"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="col-md-6">
                  <v-text-field
                    :label="$t(`label.email`)"
                    outlined
                    v-model="input.email"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  class="select-box col-md-6"
                  v-if="locations"
                >
                  <v-select
                    v-model="input.locations"
                    :items="locations"
                    :menu-props="{ maxHeight: '400' }"
                    :label="$t(`label.location`)"
                    item-text="description"
                    item-value="id"
                    outlined
                    multiple
                    persistent-hint
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="col-md-6">
                  <v-text-field
                    :label="$t(`label.password`)"
                    outlined
                    type="password"
                    v-model="input.password"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6" class="col-md-6 m-2">
                  <v-chip
                    v-for="(location, index) in input.locations"
                    :key="index"
                    class="ma-2"
                    close
                    large
                    color="#454a49"
                    label
                    @click:close="input.locations.pop(location)"
                    text-color="white"
                    >{{
                      locations.find((x) => x.id === location).description
                    }}</v-chip
                  >
                </v-col>
              </v-row>
            </form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mb-1" color="grey darken-1" text @click="close">
            {{ $t("common.close") }}
          </v-btn>
          <v-btn class="mb-1" color="primary darken-1" type="submit">
            {{ $t("common.save") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
  async mounted() {
    await this.fetchLocation();
  },
  data() {
    return {
      dialog: false,
      input: {},
      editorItems: [
        { title: this.$t(`label.editor`), val: true },
        { title: this.$t(`label.visualizer`), val: false },
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchLocation: "dashboard/fetchLocation",
      addLocationUser: "users/addLocationUser",
      addWarehouseUser: "users/addWarehouseUser",
      updateUser: "users/updateUser",
    }),
    submit() {
      this.input.editor = true;
      if (this.$refs.form.validate()) {
        const params = {
          first_name: this.input.first_name,
          last_name: this.input.last_name,
          email: this.input.email,
          username: this.input.username,
          password: this.input.password,
        };
        if (this.input.id) {
          // call update user api
          Object.assign(params, { old_password: this.input.old_password });
          this.updateUser({ params, id: this.input.id })
            .then(() => this.$emit("refresh-user"))
            .catch((error) => console.log(error));
        } else {
          if (this.user_role == "warehouse_user") {
            return new Promise((resolve, reject) => {
              Vue.prototype.$api.users
                .getLocationAreas(this.input.locations[0])
                .then(async ({ data }) => {
                  resolve(data);
                  this.input.auto_area_id = data[0].id;
                  this.addWarehouseUser(this.input)
                    .then(() => {
                      this.$emit("refresh-user");
                      this.close();
                    })
                    .catch((error) => console.log(error));
                  this.close();
                })
                .catch((error) => reject(error));
            });
          } else {
            return new Promise((resolve, reject) => {
              Vue.prototype.$api.users
                .getLocationAreas(this.input.locations[0])
                .then(async ({ data }) => {
                  resolve(data);
                  this.input.auto_area_id = data[0].id;
                  this.addLocationUser(this.input)
                    .then(() => {
                      this.$emit("refresh-user");
                      this.close();
                    })
                    .catch((error) => console.log(error));
                  this.close();
                })
                .catch((error) => reject(error));
            });
          }
        }
        this.close();
      }
    },
    open(item) {
      this.user_role = item;
      this.input = { ...item };
      this.dialog = true;
    },
    close() {
      this.$refs.form.resetValidation();
      this.dialog = false;
    },
  },
  computed: {
    ...mapGetters({
      locations: "dashboard/getLocations",
    }),
  },
};
</script>
